export const TIMEZONES = [
	{ label: 'America/Puerto_Rico (-04:00)', value: 'America/Puerto_Rico' },
	{ label: 'America/Cancun (-05:00)', value: 'America/Cancun' },
	{ label: 'America/Bahia_Banderas (-06:00)', value: 'America/Bahia_Banderas' },
	{ label: 'America/Matamoros (-06:00)', value: 'America/Matamoros' },
	{ label: 'America/Mexico_City (-06:00)', value: 'America/Mexico_City' },
	{ label: 'America/Merida (-06:00)', value: 'America/Merida' },
	{ label: 'America/Monterrey (-06:00)', value: 'America/Monterrey' },
	{ label: 'America/Chihuahua (-07:00)', value: 'America/Chihuahua' },
	{ label: 'America/Hermosillo (-07:00)', value: 'America/Hermosillo' },
	{ label: 'America/Mazatlan (-07:00)', value: 'America/Mazatlan' },
	{ label: 'America/Ojinaga (-07:00)', value: 'America/Ojinaga' },
	{ label: 'America/Santa_Isabel (-08:00)', value: 'America/Santa_Isabel' },
	{ label: 'America/Tijuana (-08:00)', value: 'America/Tijuana' },
];
