import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faRing } from '@fortawesome/free-solid-svg-icons';
import './index.scss';
import '@vueform/multiselect/themes/default.css';
import 'animate.css';

/* add icons to the library */
library.add(faRing);

createApp(App).use(router).component('font-awesome-icon', FontAwesomeIcon).mount('#app');
