/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import Cookies from 'universal-cookie';

import {
    Application, Company, CompanyPayload, Permission, Role, UserAuditLog, UserPayload, Venue,
    VenuePayload
} from '@/types';
import { User } from '@tixby/core-lib';

const cookies = new Cookies();

const TIX_AUTH = process.env.VUE_APP_TIX_AUTH || '';
const AXIOS_CONFIG = () => {
	return {
		headers: { Authorization: `Bearer ${localStorage.getItem('sessionToken')}` },
	};
};

export const authenticate = async (email: string, password: string, redirectTo?: string): Promise<string> => {
	try {
		const { data } = await axios.post(TIX_AUTH, { email, password, app: 'portal' });

		if (!data.sessionToken || !data.refreshToken) return 'failed';

		const options: any = {};
		if (process.env.NODE_ENV !== 'development') {
			options.domain = `.${location.host.split('.').slice(-2).join('.')}`;
		}

		cookies.set('sessionToken', data.sessionToken, options);
		cookies.set('refreshToken', data.refreshToken, options);
		localStorage.setItem('sessionToken', data.sessionToken);
		localStorage.setItem('refreshToken', data.refreshToken);

		if (data.changeAtNextLogin) {
			cookies.set('rotatePassword', true);
			localStorage.setItem('rotatePassword', '1');
			window.location.href = `/#/rotate-password?rt=${redirectTo || data.redirectUrl}`;
			return 'redirect';
		} else {
			cookies.remove('rotatePassword');
			localStorage.removeItem('rotatePassword');
		}

		if (data.redirectUrl) {
			window.location.href = redirectTo || data.redirectUrl;
			return 'redirect';
		}

		return 'success';
	} catch (error) {
		return 'failed';
	}
};

export const forgotPassword = async (email: string): Promise<boolean> => {
	try {
		await axios.post(`${TIX_AUTH}/users/password`, { email, app: 'portal' });
		return true;
	} catch (error) {
		return false;
	}
};

export const resetPassword = async (password: string, token: string): Promise<boolean> => {
	try {
		await axios.patch(`${TIX_AUTH}/users/password/${token}`, { password, app: 'portal' });
		return true;
	} catch (error) {
		return false;
	}
};

export const rotatePassword = async (password: string): Promise<boolean> => {
	try {
		await axios.patch(`${TIX_AUTH}/users/password/rotate`, { password, app: 'portal' }, AXIOS_CONFIG());
		cookies.remove('rotatePassword');
		localStorage.removeItem('rotatePassword');
		return true;
	} catch (error) {
		return false;
	}
};

export const verifyEmail = async (password: string, token: string): Promise<boolean> => {
	try {
		await axios.post(`${TIX_AUTH}/users/verify`, { token, password, app: 'portal' }, AXIOS_CONFIG());
		return true;
	} catch (error) {
		return false;
	}
};

export const getUsers = async (): Promise<User[]> => {
	const request = async () => {
		const { data } = await axios.get(`${TIX_AUTH}/users`, AXIOS_CONFIG());
		return data.users;
	};
	try {
		return await request();
	} catch (error) {
		if ((error as any).response.status === 403) return await refreshToken(request);
		return [];
	}
};

export const getUser = async (userId: string): Promise<User | null> => {
	const request = async () => {
		const { data } = await axios.get(`${TIX_AUTH}/users/${userId}`, AXIOS_CONFIG());
		return data.user;
	};
	try {
		return await request();
	} catch (error) {
		if ((error as any).response.status === 403) return await refreshToken(request);
		return null;
	}
};

export const createUser = async (user: UserPayload): Promise<User> => {
	const request = async () => {
		const { data } = await axios.post(`${TIX_AUTH}/users`, user, AXIOS_CONFIG());
		return data.user;
	};
	try {
		return await request();
	} catch (error) {
		if ((error as any).response.status === 403) return await refreshToken(request);
		throw error;
	}
};

export const updateUser = async (user: UserPayload): Promise<User> => {
	const request = async () => {
		const { data } = await axios.patch(`${TIX_AUTH}/users/${user._id}`, user, AXIOS_CONFIG());
		return data.user;
	};
	try {
		return await request();
	} catch (error) {
		if ((error as any).response.status === 403) return await refreshToken(request);
		throw error;
	}
};

export const getRole = async (roleId: string): Promise<Role | null> => {
	const request = async () => {
		const { data } = await axios.get(`${TIX_AUTH}/roles/${roleId}`, AXIOS_CONFIG());
		return data.role;
	};
	try {
		return await request();
	} catch (error) {
		if ((error as any).response.status === 403) return await refreshToken(request);
		return null;
	}
};

export const createRole = async (role: Role): Promise<Role> => {
	const request = async () => {
		const { data } = await axios.post(`${TIX_AUTH}/roles`, role, AXIOS_CONFIG());
		return data.role;
	};
	try {
		return await request();
	} catch (error) {
		if ((error as any).response.status === 403) return await refreshToken(request);
		throw error;
	}
};

export const updateRole = async (role: Role): Promise<Role> => {
	const request = async () => {
		const { data } = await axios.patch(`${TIX_AUTH}/roles/${role._id}`, role, AXIOS_CONFIG());
		return data.role;
	};
	try {
		return await request();
	} catch (error) {
		if ((error as any).response.status === 403) return await refreshToken(request);
		throw error;
	}
};

export const getLogs = async (userId: string): Promise<UserAuditLog[]> => {
	const request = async () => {
		const { data } = await axios.get(`${TIX_AUTH}/users/${userId}/logs`, AXIOS_CONFIG());
		return data.logs;
	};
	try {
		return await request();
	} catch (error) {
		if ((error as any).response.status === 403) return await refreshToken(request);
		return [];
	}
};

export const purgeSessions = async (): Promise<any> => {
	const request = async () => {
		return await axios.delete(`${TIX_AUTH}/users`, AXIOS_CONFIG());
	};
	try {
		return await request();
	} catch (error) {
		if ((error as any).response.status === 403) return await refreshToken(request);
		return;
	}
};

export const forcePasswordRotation = async (): Promise<any> => {
	const request = async () => {
		return await axios.delete(`${TIX_AUTH}/users/password`, AXIOS_CONFIG());
	};
	try {
		return await request();
	} catch (error) {
		if ((error as any).response.status === 403) return await refreshToken(request);
		return;
	}
};

export const refreshToken = async (retryRequest: () => Promise<any>): Promise<any> => {
	try {
		const { data } = await axios.get(`${TIX_AUTH}/token`, { params: { token: localStorage.getItem('refreshToken') } });
		cookies.set('sessionToken', data.sessionToken);
		localStorage.setItem('sessionToken', data.sessionToken);

		return await retryRequest();
	} catch (error) {
		cookies.remove('sessionToken');
		cookies.remove('refreshToken');
		cookies.remove('rotatePassword');
		localStorage.clear();
		window.location.reload();
	}
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const uploadImages = async (userId: string, file: any): Promise<any> => {
	const request = async () => {
		const response = await axios.patch(`${TIX_AUTH}/users/${userId}/picture`, file, {
			headers: { ...AXIOS_CONFIG().headers, 'Content-Type': 'multipart/form-data' },
		});

		return response.data;
	};

	try {
		return await request();
	} catch (error) {
		if ((error as any).response.status === 403) return await refreshToken(request);
		return null;
	}
};

export const uploadCompanyImages = async (companyId: string, file: any): Promise<any> => {
	const request = async () => {
		const response = await axios.patch(`${TIX_AUTH}/companies/${companyId}/picture`, file, {
			headers: { ...AXIOS_CONFIG().headers, 'Content-Type': 'multipart/form-data' },
		});

		return response.data;
	};

	try {
		return await request();
	} catch (error) {
		if ((error as any).response.status === 403) return await refreshToken(request);
		return null;
	}
};

export const getRoles = async (): Promise<any[]> => {
	const request = async () => {
		const { data } = await axios.get(`${TIX_AUTH}/users/roles`, AXIOS_CONFIG());

		const group: { [key: string]: any } = {};

		for (const role of data.roles as Role[]) {
			if (!group[role.app._id]) group[role.app._id] = { ...role.app, options: [] };
			group[role.app._id].options.push(role);
		}

		const roles = data.roles.map((r: any) => ({ ...r, name: `${r.name} (${r.app.name})` }));
		return [...roles];
	};
	try {
		return await request();
	} catch (error) {
		if ((error as any).response.status === 403) return await refreshToken(request);
		return [];
	}
};

export const getAppsRolesPermissions = async (): Promise<{ [p: string]: any }> => {
	const request = async () => {
		const { data } = await axios.get(`${TIX_AUTH}/users/roles`, AXIOS_CONFIG());

		const group: { [key: string]: any } = {};

		for (const role of data.roles as Role[]) {
			if (!group[role.app._id]) group[role.app._id] = { ...role.app, options: [] };
			group[role.app._id].options.push(role);
		}

		return group;
	};
	try {
		return await request();
	} catch (error) {
		if ((error as any).response.status === 403) return await refreshToken(request);
		return [];
	}
};

export const getUserApps = async (): Promise<any[]> => {
	const request = async () => {
		const { data } = await axios.get(`${TIX_AUTH}/users/apps`, AXIOS_CONFIG());

		return data.apps;
	};
	try {
		return await request();
	} catch (error) {
		if ((error as any).response.status === 403) return await refreshToken(request);
		return [];
	}
};

export const getAllApps = async (): Promise<any[]> => {
	const request = async () => {
		const { data } = await axios.get(`${TIX_AUTH}/apps`, AXIOS_CONFIG());
		return data.apps.map((a: Application) => ({ ...a, label: a.name }));
	};
	try {
		return await request();
	} catch (error) {
		if ((error as any).response.status === 403) return await refreshToken(request);
		return [];
	}
};

export const getConfig = async (): Promise<{ apps: Application[]; permissions: Permission[] }> => {
	const request = async () => {
		const {
			data: { apps, permissions },
		} = await axios.get(`${TIX_AUTH}/roles/config`, AXIOS_CONFIG());
		return {
			apps: apps.map((a: Application) => ({ ...a, label: a.name })),
			permissions: permissions.map((p: Permission) => ({ ...p, label: p.name })),
		};
	};
	try {
		return await request();
	} catch (error) {
		if ((error as any).response.status === 403) return await refreshToken(request);
		return { apps: [], permissions: [] };
	}
};

export const getAppPermissions = async (appId: string): Promise<any[]> => {
	const request = async () => {
		const { data } = await axios.get(`${TIX_AUTH}/apps/${appId}/permissions`, AXIOS_CONFIG());
		const p = data.permissions.map((p: Permission) => ({ ...p, label: p.name }));
		return p;
	};
	try {
		return await request();
	} catch (error) {
		if ((error as any).response.status === 403) return await refreshToken(request);
		return [];
	}
};

export const createApp = async (app: Application): Promise<Company> => {
	const request = async () => {
		const { data } = await axios.post(`${TIX_AUTH}/apps`, app, AXIOS_CONFIG());
		return data.app;
	};
	try {
		return await request();
	} catch (error) {
		if ((error as any).response.status === 403) return await refreshToken(request);
		throw error;
	}
};

export const updateApp = async (app: Application): Promise<Company> => {
	const request = async () => {
		const { data } = await axios.patch(`${TIX_AUTH}/apps/${app._id}`, app, AXIOS_CONFIG());
		return data.app;
	};
	try {
		return await request();
	} catch (error) {
		if ((error as any).response.status === 403) return await refreshToken(request);
		throw error;
	}
};

export const getUserData = async (): Promise<any> => {
	const request = async () => {
		const { data } = await axios.get(`${TIX_AUTH}/users/me`, AXIOS_CONFIG());

		return data.user;
	};
	try {
		return await request();
	} catch (error) {
		if ((error as any).response.status === 403) return await refreshToken(request);
		return null;
	}
};

export const getCompanies = async (): Promise<Company[]> => {
	const request = async () => {
		const { data } = await axios.get(`${TIX_AUTH}/companies`, AXIOS_CONFIG());
		return data.companies;
	};
	try {
		return await request();
	} catch (error) {
		if ((error as any).response.status === 403) return await refreshToken(request);
		return [];
	}
};

export const getCompany = async (id: string): Promise<Company | null> => {
	const request = async () => {
		const { data } = await axios.get(`${TIX_AUTH}/companies/${id}`, AXIOS_CONFIG());
		return { ...data.company, bankAccount: data.company.bankAccount || {} };
	};
	try {
		return await request();
	} catch (error) {
		if ((error as any).response.status === 403) return await refreshToken(request);
		return null;
	}
};

export const updateCompany = async (company: CompanyPayload): Promise<Company> => {
	const request = async () => {
		const { data } = await axios.patch(`${TIX_AUTH}/companies/${company._id}`, company, AXIOS_CONFIG());
		return data.companies;
	};
	try {
		return await request();
	} catch (error) {
		if ((error as any).response.status === 403) return await refreshToken(request);
		throw error;
	}
};

export const createCompany = async (company: CompanyPayload): Promise<Company> => {
	const request = async () => {
		const { data } = await axios.post(`${TIX_AUTH}/companies`, company, AXIOS_CONFIG());
		return data.company;
	};
	try {
		return await request();
	} catch (error) {
		if ((error as any).response.status === 403) return await refreshToken(request);
		throw error;
	}
};

export const getVenues = async (): Promise<Venue[]> => {
	const request = async () => {
		const { data } = await axios.get(`${TIX_AUTH}/venues`, { ...AXIOS_CONFIG(), params: { all: 1 } });
		return data;
	};
	try {
		return await request();
	} catch (error) {
		if ((error as any).response.status === 403) return await refreshToken(request);
		return [];
	}
};

export const getVenue = async (id: string): Promise<Venue | null> => {
	const request = async () => {
		const { data } = await axios.get(`${TIX_AUTH}/venues/${id}`, AXIOS_CONFIG());
		return data;
	};
	try {
		return await request();
	} catch (error) {
		if ((error as any).response.status === 403) return await refreshToken(request);
		return null;
	}
};

export const updateVenue = async (venue: VenuePayload): Promise<Venue> => {
	const request = async () => {
		const { data } = await axios.patch(`${TIX_AUTH}/venues/${venue._id}`, venue, AXIOS_CONFIG());
		return data;
	};
	try {
		return await request();
	} catch (error) {
		if ((error as any).response.status === 403) return await refreshToken(request);
		throw error;
	}
};

export const createVenue = async (venue: VenuePayload): Promise<Venue> => {
	const request = async () => {
		const { data } = await axios.post(`${TIX_AUTH}/venues`, venue, AXIOS_CONFIG());
		return data;
	};
	try {
		return await request();
	} catch (error) {
		if ((error as any).response.status === 403) return await refreshToken(request);
		throw error;
	}
};
